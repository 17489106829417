/**
 * common
 */
import request from '@/utils/request'

function uploadImage(FormData) {
  return request({
    method: 'POST',
    url: '/erp/api/v1/common/uploadImg',
    data: FormData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export default {
  uploadImage,
  pathObj: {
    productBaseImg: 'product/baseimage',
    shopBaseImage: 'shop/baseimage',
    planImage: 'shop/planimage',
    guidImage: 'shop/guidimage',
    mainImage: 'online/main',
    detailsImage: 'online/details',
    sizeImageId: 'online/size',
  }
}
